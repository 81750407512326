import * as React from "react"
import { useTranslation } from "react-i18next"

import { Grid, TextField, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeaderBio from '../components/header-bio';
import LayoutContainer from '../components/layout-container';
import ButtonLoading from '../components/button-loading';

import "../styles/contact.scss";
import { FaGithub } from '@react-icons/all-files/fa/FaGithub';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf';
import { FaEnvelope } from '@react-icons/all-files/fa/FaEnvelope';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

enum SentStatus {
  NONE = 0,
  SENDING,
  SENT,
  ERROR,
}

const ContactPage = () => {
  const {t} = useTranslation("contact")

  let sentStatusTimeout: ReturnType<typeof setTimeout>;

  const clearAlertTimeout = () => {
    if (sentStatusTimeout) {
      clearTimeout(sentStatusTimeout);
    }
  }

  const [sentStatus, setSentStatus] = React.useState(SentStatus.NONE);

  const sendContact = (event) => {
    event.preventDefault();

    setSentStatus(SentStatus.SENDING);
    clearAlertTimeout();

    const formData = new FormData(event.target);

    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        name: formData.get('name'),
        email: formData.get('email'),
        subject: formData.get('subject'),
        message: formData.get('message'),
      })
    };

    fetch('/api/contact', requestOptions)
      .then(async(response) => {
        if (!response.ok) {
          throw new Error(response.status + " Failed Fetch ");
        }
        return response;
      })
      .then(res => res.json())
      .then(res => {
        if ( !res.sent ) {
          throw new Error('Server returned sent: false');
        }
        return res;
      })
      .then(() => {
        setSentStatus(SentStatus.SENT);
        event.target.reset();
      })
      .catch(() => setSentStatus(SentStatus.ERROR))
      .finally(() => {
        clearAlertTimeout();
        sentStatusTimeout = setTimeout(() => setSentStatus(SentStatus.NONE), 4000);
      })
  }

  return (
    <Layout>
      <Seo title={t("title")}/>
      <HeaderBio/>
      <LayoutContainer>
        <section className="contact">

          <div className="contact-buttons">
            <Grid container justify="center">
              <Button variant="outlined">
                <a target="_blank" href="https://github.com/higorcavalcanti">
                  <FaGithub/> {t('buttons.github')}
                </a>
              </Button>
              <Button variant="outlined">
                <a target="_blank" href="https://www.linkedin.com/in/higor-cavalcanti/">
                  <FaLinkedin/> {t('buttons.linkedin')}
                </a>
              </Button>
              {/*<Button variant="outlined">*/}
              {/*  <a target="_blank" href="https://www.linkedin.com/in/higor-cavalcanti/">  */}
              {/*    <FaFilePdf/> {t('buttons.curriculum')}*/}
              {/*  </a>  */}
              {/*</Button>*/}
              <Button variant="outlined">
                <a target="_blank" href="mailto:higordaniell@live.com">
                  <FaEnvelope/> {t('buttons.email')}
                </a>
              </Button>
            </Grid>
          </div>

          <h1 className="text-center">{t("title")}:</h1>
          <form onSubmit={sendContact}>
            <div className="form-group">
              <TextField variant="outlined" fullWidth id="name" name="name"
                         label={t('form.name.label')} placeholder={t('form.name.placeholder')}/>
            </div>

            <div className="form-group">
              <TextField variant="outlined" fullWidth type="email" id="email" name="email"
                         label={t('form.email.label')} placeholder={t('form.email.placeholder')}/>
            </div>

            <div className="form-group">
              <TextField variant="outlined" fullWidth id="subject" name="subject" required
                         label={t('form.subject.label')} placeholder={t('form.subject.placeholder')}/>
            </div>

            <div className="form-group">
              <TextField variant="outlined" multiline fullWidth id="message" name="message" required
                         label={t('form.message.label')} placeholder={t('form.message.placeholder')}/>
            </div>

            {sentStatus === SentStatus.SENT && (<Alert severity="success">{t('sent.success')}</Alert>)}
            {sentStatus === SentStatus.ERROR && (<Alert severity="error">{t('sent.error')}</Alert>)}


            <Grid container justify="center">
              <ButtonLoading type="submit" variant="contained" color="primary"
                             loading={sentStatus === SentStatus.SENDING}>
                {t('form.button')}
              </ButtonLoading>
            </Grid>

          </form>
        </section>
      </LayoutContainer>
    </Layout>
  )
}

export default ContactPage
