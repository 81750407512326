import * as React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button, { ButtonProps } from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);


export interface ButtonLoadingProps {
  loading?: boolean;
  success?: boolean;
  onClick?: () => void;
}

export type ButtonLoadingType = ButtonLoadingProps & ButtonProps;

const ButtonLoading: React.FC<ButtonLoadingType> = (props) => {
  const classes = useStyles();
  const buttonClassname = clsx({
    [classes.buttonSuccess]: props.success,
  });

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant={props.variant}
          color={props.color}
          type={props.type}
          className={buttonClassname}
          disabled={props.loading}
          onClick={props.onClick}
        >
          { props.children }
        </Button>
        {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
}

export default ButtonLoading
