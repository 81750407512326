import * as React from "react"
import { useTranslation } from 'react-i18next';

import "../styles/header-bio.scss";
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';

const HeaderBio: React.FC = () => {
  const {t} = useTranslation("header")

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <section className="header-bio">
      <div className="header-bio-overlay">
        <div className="header-bio-container container">

          <Grid container justify="center" alignContent="center" alignItems="center" spacing={2}>

            <Grid item sm={3} md={2}>
              <div className="header-bio-picture">
                <img src="https://www.higorcavalcanti.com.br/pt/assets/images/Higor-Cavalcanti.jpg"
                     alt="Higor Cavalcanti"/>
              </div>
            </Grid>

            <Grid item sm>
              <div className="header-bio-text">
                <div className={'header-bio-author ' + (isMobile ? 'text-center' : '')}>
                  Higor Cavalcanti
                </div>
                <div className="header-bio-description">
                  {t("bio1")} {t("bio2")} {t("bio3")}
                </div>
              </div>
            </Grid>
          </Grid>


        </div>
      </div>
    </section>
  );
}

export default HeaderBio
